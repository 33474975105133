// import { format } from "prettier"
import { element } from "prop-types"
import { TbAirConditioningDisabled } from "react-icons/tb"
import { createStore as reduxCreateStore, applyMiddleware } from "redux"
import thunk from "redux-thunk"

const reducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    return { ...initialState, loginMessage: action.data }
  }
  if (action.type === "SET_STATE") {
    return { ...state, [action.data.prop]: action.data.val }
  }
  if (action.type === "LOADING_START") {
    console.log("load 'em up")
    return { ...state, isLoading: true }
  }
  if (action.type === "LOADING_TOKEN_CONFIG") {
    console.log("load 'em up")
    return { ...state, isLoadingTokenConfig: true }
  }
  if (action.type === "LOADING_NOTIFY_CREATE") {
    console.log("load 'em up")
    return { ...state, isLoadingNotifyCreate: true }
  }
  if (action.type === "LOADING_NOTIFY_RESET") {
    return {
      ...state,
      isLoadingNotifyCreate: false,
      forms: {
        ...state.forms,
        notify: { media: [] },
      },
    }
  }
  if (action.type === "LOADING_END") {
    return {
      ...state,
      isLoading: false,
      isLoadingVideoCreatedGrouped: false,
      isLoadingVideoUsers: false,
      isLoadingVideoMini: false,
      isLoadingNotifyCreate: false,
      isLoadingTokenConfig: false,
    }
  }
  if (action.type === "LOADING_CROP_START") {
    console.log("load 'em up")
    return { ...state, isLoadingStockCrop: true }
  }
  if (action.type === "LOADING_CROP_SUCCESS") {
    console.log("load 'em up")
    return { ...state, isLoadingStockCrop: "success" }
  }
  if (action.type === "LOADING_CROP_END") {
    console.log("load 'em up")
    return { ...state, isLoadingStockCrop: false }
  }

  if (action.type === "LOADING_SUBMISSION_START") {
    console.log("load 'em up")
    return { ...state, loadingSubmission: true }
  }
  if (action.type === "LOADING_MINI_START") {
    console.log("load 'em up")
    return { ...state, isLoadingMini: { [action.data.sessionid]: true } }
  }
  if (action.type === "LOADING_CANVAS_IMAGE") {
    return { ...state, isLoadingCanvasImage: action.data }
  }
  if (action.type === "CAMPAIGNS_REPLACE") {
    console.log("campaigns-replace")
    return { ...state, isLoading: false, campaign: action.data }
  }
  if (action.type === "AGREEMENT_REPLACE") {
    console.log("agreement-replace")
    return { ...state, isLoading: false, agreements: action.data }
  }
  if (action.type === "NOTIFY_REPLACE") {
    console.log("campaigns-replace")
    return { ...state, isLoading: false, notify: action.data }
  }
  if (action.type === "NOTIFY_FORMS_MEDIA_ADD") {
    console.log("NOTIFY_FORMS_MEDIA_ADD")
    console.log(action.data)
    return {
      ...state,
      isLoading: false,
      forms: {
        ...state.forms,
        notify: {
          ...state.forms.notify,
          media: [...state.forms.notify.media, action.data],
        },
      },
    }
  }
  if (action.type === "NOTIFY_FORMS_MEDIA_DELETE") {
    console.log("NOTIFY_FORMS_MEDIA_DELETE")
    console.log("action.data")
    console.log(action.data)
    console.log("state.forms.notify.media")
    console.log(state.forms.notify.media)

    return {
      ...state,
      isLoading: false,
      forms: {
        ...state.forms,
        notify: {
          ...state.forms.notify,
          media: [...state.forms.notify.media].filter(
            (item, i) => i !== action.data
          ),
        },
      },
    }
  }
  if (action.type === "CAMPAIGNS_TOACTIVATE_REPLACE") {
    console.log("campaigns-toactivate-replace")
    return { ...state, isLoading: false, campaignactivate: action.data }
  }
  if (action.type === "CAMPAIGN_ACTIVATE_REPLACE") {
    console.log("CAMPAIGN_ACTIVATE_REPLACE")
    return {
      ...state,
      loadingSubmission: false,
      submitted: true,
      campaignactivated: action.data,
    }
  }

  if (action.type === "TEMPLATE_FETCH_START") {
    console.log("TEMPLATE_FETCH_START")

    return {
      ...state,
      isLoadingTemplateFetch: true,
    }
  }
  if (action.type === "TEMPLATE_FETCH_END") {
    console.log("TEMPLATE_FETCH_END")

    return {
      ...state,
      isLoadingTemplateFetch: false,
    }
  }

  if (action.type === "TEMPLATE_CREATE_UPDATE_START") {
    console.log("TEMPLATE_CREATE_UPDATE_START")

    return {
      ...state,
      isLoadingTemplateCreate: true,
    }
  }
  if (action.type === "TEMPLATE_CREATE_UPDATE_END") {
    console.log("TEMPLATE_CREATE_UPDATE_END")

    return {
      ...state,
      isLoadingTemplateCreate: false,
    }
  }
  if (action.type === "TEMPLATE_CREATE_UPDATE_COMPLETE") {
    console.log("TEMPLATE_CREATE_UPDATE_COMPLETE")
    // console.log("action.data")
    // console.log(action.data)

    return {
      ...state,
      isLoadingTemplateCreate: false,
      isLoadingTemplateFetch: false,
      forms: {
        ...state.forms,
        // savetemplate: {},
        // edittemplate: {},
        tag: {},
        plannerTemplateSaveData: {},
      },
      // elementsOverlayData: [],
    }
  }
  if (action.type === "TEMPLATE_EDIT_UPDATE_START") {
    console.log("TEMPLATE_EDIT_UPDATE_START")

    return {
      ...state,
      isLoadingTemplateCreate: true,
    }
  }
  if (action.type === "TEMPLATE_EDIT_UPDATE_COMPLETE") {
    console.log("TEMPLATE_EDIT_UPDATE_COMPLETE")
    // console.log("action.data")
    // console.log(action.data)

    return {
      ...state,
      isLoadingTemplateCreate: false,
      forms: {
        ...state.forms,
        edittemplate: {},
        savetemplate: {},
      },
    }
  }

  if (action.type === "COLLECTION_CREATE_UPDATE_START") {
    console.log("COLLECTION_CREATE_UPDATE_START")

    return {
      ...state,
      isLoadingCollectionCreate: true,
    }
  }
  if (action.type === "COLLECTION_CREATE_UPDATE_END") {
    console.log("COLLECTION_CREATE_UPDATE_END")

    return {
      ...state,
      isLoadingCollectionCreate: false,
    }
  }
  if (action.type === "COLLECTION_CREATE_UPDATE_COMPLETE") {
    console.log("COLLECTION_CREATE_UPDATE_COMPLETE")
    // console.log("action.data")
    // console.log(action.data)

    return {
      ...state,
      isLoadingCollectionCreate: false,
      forms: {
        ...state.forms,
        saveVideoCollection: initialSaveVideoCollectionState,
      },
    }
  }
  if (action.type === "COLLECTION_POSTS_UPDATE") {
    console.log("COLLECTION_POSTS_UPDATE")
    // console.log("action.data")
    // console.log(action.data)

    return {
      ...state,
      isLoadingVideoCollectionsList: false,
      collectionPosts: action.data,
    }
  }
  if (action.type === "COLLECTION_MESSAGING_UPDATE") {
    console.log("COLLECTION_MESSAGING_UPDATE")
    // console.log("action.data")
    // console.log(action.data)

    return {
      ...state,
      isLoadingVideoCollectionsList: false,
      collectionMessaging: action.data,
    }
  }
  if (action.type === "COLLECTION_MANAGE_PLANNER_ATTRIBUTES") {
    console.log("COLLECTION_MANAGE_PLANNER_ATTRIBUTES")
    console.log("action.data")
    console.log(action.data)
    console.log("state.forms.saveVideoCollection")
    console.log(state.forms.saveVideoCollection)

    if (action.data.mode === "add") {
      return {
        ...state,
        forms: {
          ...state.forms,
          saveVideoCollection: {
            ...state.forms.saveVideoCollection,
            [action.data.group]: [
              ...state.forms.saveVideoCollection[action.data.group],
              {
                id: action.data.id,
                cat: action.data.cat,
                content: "",
                icon: "Article",
                dateattest: "",
                access: "public",
                accessgroups: [],
                mode: "content",
                elementexport: false,
                // category: "",
              },
            ],
          },
        },
      }
    } else if (action.data.mode === "delete") {
      return {
        ...state,
        forms: {
          ...state.forms,
          saveVideoCollection: {
            ...state.forms.saveVideoCollection,
            [action.data.group]: state.forms.saveVideoCollection[
              action.data.group
            ].filter((item, i) => item.id !== action.data.id),
          },
        },
      }
    } else if (action.data.mode === "deleteevent") {
      return {
        ...state,
        forms: {
          ...state.forms,
          saveVideoCollection: {
            ...state.forms.saveVideoCollection,
            [action.data.group]: state.forms.saveVideoCollection[
              action.data.group
            ].filter((item, i) => i !== action.data.id),
          },
        },
      }
    } else if (action.data.mode === "updatecontent") {
      return {
        ...state,
        forms: {
          ...state.forms,
          saveVideoCollection: {
            ...state.forms.saveVideoCollection,
            plannerAttributesSelected: [
              ...state.forms.saveVideoCollection.plannerAttributesSelected.map(
                (attribute) => {
                  if (attribute.id === action.data.id) {
                    return {
                      ...attribute,
                      content: action.data.content,
                    }
                  } else {
                    return attribute
                  }
                }
              ),
            ],
          },
        },
      }
    } else if (action.data.mode === "updatecat") {
      return {
        ...state,
        forms: {
          ...state.forms,
          saveVideoCollection: {
            ...state.forms.saveVideoCollection,
            plannerAttributesSelected: [
              ...state.forms.saveVideoCollection.plannerAttributesSelected.map(
                (attribute) => {
                  if (attribute.id === action.data.id) {
                    return {
                      ...attribute,
                      cat: action.data.content,
                    }
                  } else {
                    return attribute
                  }
                }
              ),
            ],
          },
        },
      }
    } else if (action.data.mode === "updatecatsub") {
      return {
        ...state,
        forms: {
          ...state.forms,
          saveVideoCollection: {
            ...state.forms.saveVideoCollection,
            plannerAttributesSelected: [
              ...state.forms.saveVideoCollection.plannerAttributesSelected.map(
                (attribute) => {
                  if (attribute.id === action.data.id) {
                    return {
                      ...attribute,
                      id: action.data.content,
                    }
                  } else {
                    return attribute
                  }
                }
              ),
            ],
          },
        },
      }
    } else if (action.data.mode === "updateelementexport") {
      return {
        ...state,
        forms: {
          ...state.forms,
          saveVideoCollection: {
            ...state.forms.saveVideoCollection,
            plannerAttributesSelected: [
              ...state.forms.saveVideoCollection.plannerAttributesSelected.map(
                (attribute) => {
                  if (attribute.id === action.data.id) {
                    return {
                      ...attribute,
                      elementexport: action.data.content,
                    }
                  } else {
                    return attribute
                  }
                }
              ),
            ],
          },
        },
      }
    }
  }
  if (action.type === "COLLECTION_MANAGE_DATAFIELDS") {
    console.log("COLLECTION_MANAGE_DATAFIELDS")
    console.log("action.data")
    console.log(action.data)
    console.log("state.forms.saveVideoCollection")
    console.log(state.forms.saveVideoCollection)

    if (action.data.mode === "add") {
      return {
        ...state,
        forms: {
          ...state.forms,
          saveVideoCollection: {
            ...state.forms.saveVideoCollection,
            [action.data.prop]: [
              ...state.forms.saveVideoCollection[action.data.prop],
              { title: "", content: "", fieldtype: "input" },
            ],
          },
        },
      }
    } else if (action.data.mode === "delete") {
      return {
        ...state,
        forms: {
          ...state.forms,
          saveVideoCollection: {
            ...state.forms.saveVideoCollection,
            [action.data.prop]: state.forms.saveVideoCollection[
              action.data.prop
            ].filter((link, i) => i !== action.data.pos),
          },
        },
      }
    }
  }
  if (action.type === "COLLECTION_MANAGE_LINKS") {
    console.log("COLLECTION_MANAGE_LINKS")
    console.log("action.data")
    console.log(action.data)

    if (action.data.mode === "add") {
      return {
        ...state,
        forms: {
          ...state.forms,
          saveVideoCollection: {
            ...state.forms.saveVideoCollection,
            [action.data.prop]: [
              ...state.forms.saveVideoCollection[action.data.prop],
              { title: "", protocol: "https", href: "" },
            ],
          },
        },
      }
    } else if (action.data.mode === "delete") {
      return {
        ...state,
        forms: {
          ...state.forms,
          saveVideoCollection: {
            ...state.forms.saveVideoCollection,
            [action.data.prop]: state.forms.saveVideoCollection[
              action.data.prop
            ].filter((link, i) => i !== action.data.pos),
          },
        },
      }
    }
  }
  if (action.type === "COLLECTION_MANAGE_VIDEOS") {
    console.log("COLLECTION_MANAGE_VIDEOS")
    console.log("action.data")
    console.log(action.data)

    if (action.data.mode === "add") {
      return {
        ...state,
        forms: {
          ...state.forms,
          saveVideoCollection: {
            ...state.forms.saveVideoCollection,
            [action.data.prop]: [
              ...state.forms.saveVideoCollection[action.data.prop],
              { ...action.data.videoItem, sort: action.data.pos, price: 0.1 },
            ],
          },
        },
      }
    } else if (action.data.mode === "addnft") {
      let defaultPrice
      let networkValue
      switch (action.data.videoItem.chainId) {
        case 1:
          defaultPrice = 0.1
          networkValue = "mainnet"
          break
        case 5:
          defaultPrice = 0.1
          networkValue = "goerli"
          break
        case 137:
          defaultPrice = 1
          networkValue = "matic"
          break
        case 80001:
          defaultPrice = 1
          networkValue = "mumbai"
          break
        case 11155111:
          defaultPrice = 0.1
          networkValue = "sepolia"
          break
        case 80002:
          defaultPrice = 1
          networkValue = "amoy"
          break
        default:
          defaultPrice = 0
      }

      let manualChainId
      if (action.data.videoItem.mode === "manual") {
        switch (state.forms.saveVideoCollection.manualNetwork) {
          case "mainnet":
            manualChainId = 1
            break
          case "goerli":
            manualChainId = 5
            break
          case "matic":
            manualChainId = 137
            break
          case "mumbai":
            manualChainId = 80001
            break
          default:
            manualChainId = 0
        }
      }
      return {
        ...state,
        forms: {
          ...state.forms,
          saveVideoCollection: {
            ...state.forms.saveVideoCollection,
            [action.data.prop]: [
              ...state.forms.saveVideoCollection[action.data.prop],
              {
                contract: action.data.videoItem.contract,
                contractAddr: action.data.videoItem.contract.address,
                contractMode: action.data.videoItem.tokenType.replace(
                  "ERC",
                  ""
                ),
                description: action.data.videoItem.description,
                media: action.data.videoItem.raw?.metadata?.animation_url,
                metadata: action.data.videoItem.raw?.metadata,
                poster: action.data.videoItem?.image?.originalUrl,
                title: action.data.videoItem.name,
                tokenId: action.data.videoItem.tokenId,
                manualTokenIdMin:
                  state.forms.saveVideoCollection.manualTokenIdMin, //different
                manualTokenIdMax:
                  state.forms.saveVideoCollection.manualTokenIdMax, //different
                mode: action.data.videoItem.mode,
                sort: action.data.pos,
                chainId:
                  action.data.videoItem.mode === "manual"
                    ? manualChainId
                    : action.data.videoItem.chainId,
                network:
                  action.data.videoItem.mode === "manual"
                    ? state.forms.saveVideoCollection.manualNetwork
                    : networkValue,
                // network: action.data.videoItem.network,
                // networkName: action.data.videoItem.networkName,
                supplyAvail: action.data.videoItem.balance,
                supplySelected: action.data.videoItem.balance,
                price: defaultPrice,
                //owner is added when data is validated
              },
            ],
          },
        },
      }
    } else if (action.data.mode === "addnftoffchain") {
      return {
        ...state,
        forms: {
          ...state.forms,
          saveVideoCollection: {
            ...state.forms.saveVideoCollection,
            [action.data.prop]: [
              ...state.forms.saveVideoCollection[action.data.prop],
              {
                description: action.data.videoItem.desc,
                // poster: action.data.videoItem.poster,
                poster: action.data.videoItem.plannerProductMedia.asset,
                title: action.data.videoItem.title,
                id: action.data.videoItem.id,
                anonUserId: action.data.videoItem.anonUserId,
                sort: action.data.pos,
                // network: action.data.videoItem.network,
                // networkName: action.data.videoItem.networkName,
                supply: action.data.videoItem.supply,
                //owner is added when data is validated
              },
            ],
          },
        },
      }
    } else if (action.data.mode === "delete") {
      return {
        ...state,
        forms: {
          ...state.forms,
          saveVideoCollection: {
            ...state.forms.saveVideoCollection,
            [action.data.prop]: state.forms.saveVideoCollection[
              action.data.prop
            ].filter((link, i) => i !== action.data.pos),
          },
        },
      }
    } else if (action.data.mode === "edit") {
      return {
        ...state,
        forms: {
          ...state.forms,
          saveVideoCollection: {
            ...state.forms.saveVideoCollection,
            [action.data.prop]: state.forms.saveVideoCollection[
              action.data.prop
            ].map((propItem, i) => {
              if (i === action.data.pos) {
                return { ...propItem, [action.data.subprop]: action.data.val }
              } else {
                return propItem
              }
            }),
          },
        },
      }
    } else if (action.data.mode === "reset") {
      return {
        ...state,
        forms: {
          ...state.forms,
          saveVideoCollection: {
            ...state.forms.saveVideoCollection,
            [action.data.prop]: [],
          },
        },
      }
    }
  }

  if (action.type === "COLLECTION_EVENT_EDIT_COPY") {
    console.log("COLLECTION_EVENT_EDIT_COPY")
    console.log(action.data)
    return {
      ...state,
      forms: {
        ...state.forms,
        saveVideoCollection: action.data
          ? {
              ...state.forms.saveVideoCollection, //new
              ...action.data,
              eventSendMessage: [
                {
                  cat:
                    action.data && action.data.eventSendCategory
                      ? action.data.eventSendCategory
                      : "",
                  content:
                    action.data && action.data.message
                      ? action.data.message
                      : "",
                  dateattest:
                    action.data &&
                    action.data.utility &&
                    action.data.utility.dateattest
                      ? action.data.utility.dateattest
                      : "",
                  mode:
                    action.data &&
                    action.data.utility &&
                    action.data.utility.mode
                      ? action.data.utility.mode
                      : "",
                  access:
                    action.data &&
                    action.data.utility &&
                    action.data.utility.access
                      ? action.data.utility.access
                      : "",
                  accessgroups:
                    action.data &&
                    action.data.utility &&
                    action.data.utility.accessgroups
                      ? action.data.utility.accessgroups
                      : "",
                  itemgroupid:
                    action.data &&
                    action.data.utility &&
                    action.data.utility.itemgroupid
                      ? action.data.utility.itemgroupid
                      : "",
                  icon:
                    action.data &&
                    action.data.utility &&
                    action.data.utility.icon
                      ? action.data.utility.icon
                      : "",
                  id:
                    action.data && action.data.eventSendCategorySub
                      ? action.data.eventSendCategorySub
                      : null,
                  eventtime:
                    action.data && action.data.eventTime
                      ? action.data.eventTime
                      : null,
                },
              ],
              eventSendId:
                action.data && action.data.id ? action.data.id : null,

              // eventSendCategory:
              //   action.data && action.data.eventSendCategory
              //     ? action.data.eventSendCategory
              //     : "",
              // eventSendMessage:
              //   action.data && action.data.message ? action.data.message : [],

              // eventSendIcon:
              //   action.data && action.data.utility && action.data.utility.icon
              //     ? action.data.utility.icon
              //     : "",
              // eventSendDateAttestation:
              //   action.data &&
              //   action.data.utility &&
              //   action.data.utility.dateattest
              //     ? action.data.utility.dateattest
              //     : "",
              nftAuthOffchain:
                action.data && action.data.nftAuthOffchain
                  ? action.data.nftAuthOffchain
                  : [],
            }
          : state.saveVideoCollection,
      },
    }
  }
  if (action.type === "COLLECTION_EDIT_COPY") {
    console.log("COLLECTION_EDIT_COPY")
    console.log(action.data)
    return {
      ...state,
      forms: {
        ...state.forms,
        // offchainOptions: {
        //   ...state.forms.offchainOptions,
        //   eventOffchain:
        //     action.data &&
        //     action.data.nftAuthOffchain &&
        //     action.data.nftAuthOffchain.length > 0
        //       ? true
        //       : false,
        // },
        saveVideoCollection: action.data
          ? {
              ...state.forms.saveVideoCollection, //new
              ...action.data,
              action:
                action.data.landing &&
                action.data.landing.intro &&
                action.data.landing.intro.active
                  ? "videoresume"
                  : action.data.landing &&
                    action.data.landing.sms &&
                    action.data.landing.sms.active
                  ? "sms"
                  : action.data.landing &&
                    action.data.landing.walletcollect &&
                    action.data.landing.walletcollect.active
                  ? "wallet"
                  : "none",
              metadata:
                action.data &&
                action.data.landing &&
                action.data.landing.metadata
                  ? action.data.landing.metadata
                  : 0,
              image:
                action.data && action.data.landing && action.data.landing.image
                  ? action.data.landing.image
                  : "",
              imageMarquee:
                action.data &&
                action.data.landing &&
                action.data.landing.imageMarquee
                  ? action.data.landing.imageMarquee
                  : action.data && action.data.poster
                  ? action.data.poster
                  : "",
              imageMarqueeSrc:
                action.data && action.data.imageMarqueeSrc
                  ? action.data.imageMarqueeSrc
                  : "",
              link:
                action.data && action.data.link
                  ? action.data.link
                  : action.data &&
                    action.data.landing &&
                    action.data.landing.link
                  ? action.data.landing.link
                  : [],
              cta:
                action.data && action.data.landing && action.data.landing.cta
                  ? action.data.landing.cta
                  : [],
              dataFields:
                action.data &&
                action.data.landing &&
                action.data.landing.dataFields
                  ? action.data.landing.dataFields
                  : [],
              bgcolor:
                action.data &&
                action.data.landing &&
                action.data.landing.bgcolor
                  ? action.data.landing.bgcolor
                  : "#cccccc",
              displayTitle:
                action.data &&
                action.data.landing &&
                action.data.landing.hasOwnProperty("displayTitle")
                  ? action.data.landing.displayTitle
                  : true,
              // plannerAttributesSelected:
              //   action.data &&
              //   action.data.plannerAttributesSelected &&
              //   action.data.plannerAttributesSelected.length > 0
              //     ? action.data.plannerAttributesSelected
              //     : [],
            }
          : state.saveVideoCollection,
      },
    }
  }
  if (action.type === "NOTIFY_ITEM_COPY") {
    console.log("NOTIFY_ITEM_COPY")
    console.log(action.data)
    return {
      ...state,
      forms: {
        ...state.forms,
        // offchainOptions: {
        //   ...state.forms.offchainOptions,
        //   eventOffchain:
        //     action.data &&
        //     action.data.nftAuthOffchain &&
        //     action.data.nftAuthOffchain.length > 0
        //       ? true
        //       : false,
        // },
        notify: action.data
          ? {
              ...state.forms.notify,
              id: action.data.id,
              title: action.data.title,
              attributes: action.data.attributes,
              activecollectionid: action.data.activecollectionid,
            }
          : state.forms.notify,
      },
    }
  }

  if (action.type === "CLEAR_FORM_FIELD") {
    console.log("CLEAR_FORM_FIELD")

    let val = {}
    if (
      action &&
      action.data &&
      action.data.prop &&
      action.data.prop === "workingtags"
    ) {
      val = []
    } else if (
      action &&
      action.data &&
      action.data.prop &&
      action.data.prop === "saveVideoCollection"
    ) {
      console.log("initialSaveVideoCollectionState")
      console.log(initialSaveVideoCollectionState)
      val = initialSaveVideoCollectionState
    } else if (
      action &&
      action.data &&
      action.data.prop &&
      action.data.prop === "notify"
    ) {
      console.log("initialFormsNotifyState")
      console.log(initialFormsNotifyState)
      val = initialFormsNotifyState
    }
    return {
      ...state,
      forms: {
        ...state.forms,
        [action.data.prop]: val,
      },
    }
  }

  if (action.type === "SHARE_FIELD_UPDATE") {
    console.log("SHARE_FIELD_UPDATE")
    // console.log("action.data")
    // console.log(action.data)
    if (action.data.val === "reset") {
      return {
        ...state,
        isLoading: false,
        share: {},
      }
    } else {
      return {
        ...state,
        isLoading: false,
        share: {
          ...state.share,
          [action.data.prop]: action.data.val,
        },
      }
    }
  }
  if (action.type === "ACTIVESECTION_FIELD_UPDATE") {
    // console.log("ACTIVESECTION_FIELD_UPDATE")
    // console.log("action.data")
    // console.log(action.data)

    return {
      ...state,
      activeSection: action.data,
    }
  }
  if (action.type === "ACTIVETOOL_FIELD_UPDATE") {
    // console.log("ACTIVETOOL_FIELD_UPDATE")
    // console.log("action.data")
    // console.log(action.data)

    return {
      ...state,
      activeTool: action.data,
    }
  }
  if (action.type === "EMAILVERIFY_FIELD_UPDATE") {
    // console.log("EMAILVERIFY_FIELD_UPDATE")
    // console.log("action.data")
    // console.log(action.data)

    return {
      ...state,
      emailVerifyMode: action.data,
    }
  }
  if (action.type === "EMAILVERIFY_SEND_ERRORS") {
    return {
      ...state,
      forms: {
        ...state.forms,
        userdetailemail: {
          ...state.forms.userdetailemail,
          error: action.data ? action.data : "",
        },
      },
    }
  }

  if (action.type === "EMAILVERIFY_UPDATE_COMPLETE") {
    console.log("EMAILVERIFY_UPDATE_COMPLETE")
    // console.log("action.data")
    // console.log(action.data)

    let returnObj = {
      email: state.userdetail.email,
      mobile: state.userdetail.mobile,
      verifycode: "",
      error: "",
    }

    let errorMsg
    if (action.error) errorMsg = action.error
    return {
      ...state,
      emailVerifyMode: action.data,
      forms: {
        ...state.forms,
        userdetailemail: {
          ...returnObj,
          error: errorMsg ? errorMsg : "",
        },
      },
      userdetailemail: returnObj,
    }
  }
  if (action.type === "FORMS_FIELD_UPDATE") {
    console.log("FORMS_FIELD_UPDATE")
    // console.log("action.data")
    // console.log(action.data)

    return {
      ...state,
      isLoading: false,
      forms: {
        ...state.forms,
        [action.data.prop]: action.data.val,
      },
    }
  }
  if (action.type === "PUBLISHER_FIELD_UPDATE") {
    console.log("PUBLISHER_FIELD_UPDATE")
    console.log("action.val")
    console.log(action.val)
    console.log("action.data")
    console.log(action.data)
    const currentPos = state.publisher.sceneSelected
    const currentVideoElements = { ...state.publisher.videoElements }

    return {
      ...state,
      isLoading: false,
      publisher: {
        ...state.publisher,
        loadedScenes: state.publisher.loadedScenes.map((sceneItem, i) =>
          i === currentPos
            ? {
                ...sceneItem,
                video: currentVideoElements,
              }
            : sceneItem
        ), //transfer current content  videoElements to loadedScenes
        [action.data.prop]: action.data.val,
      },
    }
  }
  if (action.type === "PUBLISHER_TEMPLATE_REMOVE") {
    console.log("PUBLISHER_TEMPLATE_REMOVE")
    // console.log("action.data")
    // console.log(action.data)

    return {
      ...state,
      isLoading: false,
      isLoadingTemplateCreate: false,
      isLoadingTemplateDeleteCompleted: true,
      publisher: {
        ...state.publisher,
        templates: state.publisher.templates.filter(
          (template) => template.id !== action.data.id
        ),
      },
      forms: {
        ...state.forms,
        edittemplate: {},
        savetemplate: {},
      },
    }
  }
  if (action.type === "PUBLISHER_TEMPLATE_DELETE_RESET") {
    console.log("PUBLISHER_TEMPLATE_DELETE_RESET")

    return {
      ...state,
      isLoadingTemplateDeleteCompleted: false,
    }
  }

  if (action.type === "PROFILE_EDIT_COPY") {
    console.log("PROFILE_EDIT_COPY")
    console.log(state.userdetail)
    return {
      ...state,
      forms: {
        ...state.forms,
        userdetail: action.data ? action.data : state.userdetail,
        userdetailemail: action.data
          ? {
              email: action.data.email,
              mobile: action.data.mobile,
              displayname: action.data.displayname,
              verifycode: "",
              error: "",
            }
          : state.userdetailemail,
      },
    }
  }
  if (action.type === "PROFILE_EDIT_EMAIL_COPY") {
    console.log("PROFILE_EDIT_EMAIL_COPY")
    console.log(state.userdetailemail)
    return {
      ...state,
      forms: {
        ...state.forms,
        userdetailemail: action.data ? action.data : state.userdetailemail,
      },
    }
  }

  if (action.type === "USER_LOGIN") {
    console.log("createStore - USER_LOGIN - action.data")
    console.log(action.data)
    return {
      ...state,
      isLoading: false,
      userlogin: action.data,
      usersub: action.data.sub,
      usererror: {},
    }
  }

  if (action.type === "USER_LOGIN_ERROR") {
    return { ...state, isLoading: false, usererror: action.data }
  }
  if (action.type === "USER_PROFILE_DETAIL_REPLACE") {
    console.log("createStore - USER_PROFILE_DETAIL_REPLACE - action.data")
    console.log(action.data)
    return {
      ...state,
      isLoading: false,
      isProfileUpdateComplete: true,
      userdetail: action.data,
      userdetailemail: {
        email: action.data.email,
        mobile: action.data.mobile,
        displayname: action.data.displayname,
        verifycode: "",
        error: "",
      },
    }
  }
  if (action.type === "USER_PROFILE_DETAIL_COMPLETE") {
    return {
      ...state,
      isProfileUpdateComplete: false,
    }
  }
  if (action.type === "COMPANY_DETAIL_UPDATE") {
    console.log("createStore - COMPANY_DETAIL_UPDATE")
    console.log(action.data)
    return {
      ...state,
      isLoading: false,
      company: { ...state.company, [action.data.prop]: action.data.val },
    }
  }

  if (action.type === "COMPANY_DETAIL_REPLACE") {
    console.log("createStore - COMPANY_DETAIL_REPLACE - action.data")
    console.log(action.data)
    // if (action.data.user) {
    //   delete action.data.user.email
    // }
    return {
      ...state,
      isLoading: false,
      company: action.data.company,
      userdetail: action.data.user,
      userdetailemail: {
        email: action.data.user.email,
        mobile:
          action.data.user && action.data.user.mobile
            ? action.data.user.mobile
            : "",
        displayname: action.data.user.displayname,
        verifycode: "",
        error: "",
      },
      usererror: {},
    }
  }
  if (action.type === "USERSUBSCRIPTION_DETAIL_REPLACE") {
    console.log("createStore - USERSUBSCRIPTION_DETAIL_REPLACE - action.data")

    return {
      ...state,
      isLoading: false,
      userSubscription: action.data,
    }
  }

  if (action.type === "SOCIALMODAL_REPLACE") {
    console.log("SOCIALMODAL_REPLACE")
    return {
      ...state,
      socialmodal: action.data,
      isLoadingMediaPreview: true,
    }
  }
  if (action.type === "SOCIALMODAL_FINISHED") {
    console.log("SOCIALMODAL_FINISHED")
    return {
      ...state,
      isLoadingMediaPreview: false,
      imageexport: action.data,
    }
  }

  if (action.type === "UPDATE_FORM_FIELD") {
    console.log("UPDATE_FORM_FIELD")
    console.log("action.data")
    console.log(action.data)
    if (
      action.data &&
      action.data.group === "saveVideoCollection" &&
      action.data.prop === "sendEventMessage" &&
      action.data.hasOwnProperty("pos")
    ) {
    } else {
      return {
        ...state,
        isLoading: false,
        forms: {
          ...state.forms,
          [action.data.group]: {
            ...state.forms[action.data.group],
            [action.data.prop]: action.data.val,
          },
        },
      }
    }
  }
  if (action.type === "UPDATE_FORM_FIELD_SUB") {
    console.log("UPDATE_FORM_FIELD_SUB")
    console.log("action.data")
    console.log(action.data)
    return {
      ...state,
      isLoading: false,
      forms: {
        ...state.forms,
        [action.data.group]: {
          ...state.forms[action.data.group],
          [action.data.prop]: {
            ...state.forms[action.data.group][action.data.prop],
            [action.data.sub]: action.data.val,
          },
        },
      },
    }
  }
  if (action.type === "UPDATE_FORM_FIELD_SINGLE") {
    console.log("UPDATE_FORM_FIELD_SINGLE")
    console.log("action.data")
    console.log(action.data)

    return {
      ...state,
      isLoading: false,
      forms: {
        ...state.forms,
        [action.data.prop]: action.data.val,
      },
    }
  }

  if (action.type === "UPDATE_FORM_FIELD_USERGROUP") {
    console.log("UPDATE_FORM_FIELD_USERGROUP")
    console.log(action.data)

    const { group, index, fname, lname, email, planmodules } = action.data

    if (
      group === "usersmodify" ||
      group === "usersmodifyemail" ||
      group === "usersmodifymodule"
    ) {
      return {
        ...state,
        isLoading: false,
        company: {
          ...state.company,
          users: state.company.users.map((userDetail, i) =>
            i === index
              ? {
                  ...userDetail,
                  index,
                  fname: fname === "" ? "" : fname ? fname : userDetail.fname,
                  lname: lname === "" ? "" : lname ? lname : userDetail.lname,
                  email: email === "" ? "" : email ? email : userDetail.email,
                  modified: true,
                  modifiedemail:
                    group === "usersmodifyemail" ||
                    userDetail.modifiedemail === true
                      ? true
                      : false,
                  planmodules:
                    group === "usersmodifymodule" &&
                    planmodules &&
                    planmodules.val === false
                      ? userDetail.planmodules.filter(
                          (module) => module !== planmodules.name
                        ) //if removing then filter through and take out the target module
                      : group === "usersmodifymodule" &&
                        planmodules &&
                        planmodules.val === true
                      ? [...userDetail.planmodules, planmodules.name] //otherwise add it to the module list
                      : userDetail.planmodules,
                }
              : userDetail
          ),
        },
      }
    } else if (group === "usersadd") {
      return {
        ...state,
        isLoading: false,
        company: {
          ...state.company,
          users: [
            ...state.company.users,
            {
              id: "",
              fname: "",
              lname: "",
              email: "",
              planmodules: [],
              add: true,
            },
          ],
        },
      }
    } else if (group === "userstempdelete") {
      return {
        ...state,
        isLoading: false,
        company: {
          ...state.company,
          users: [...state.company.users.filter((user, i) => i !== index)],
        },
      }
    } else if (group === "usersdelete" || group === "usersdeleteundo") {
      return {
        ...state,
        isLoading: false,
        company: {
          ...state.company,
          users: state.company.users.map((userDetail, i) =>
            i === index
              ? {
                  ...userDetail,
                  delete: group === "usersdelete" ? true : false,
                }
              : userDetail
          ),
        },
      }
    }
  }

  if (action.type === "UPDATE_FORM_FIELD_COLLECTION_LINK") {
    console.log("UPDATE_FORM_FIELD_COLLECTION_LINK")
    console.log("action.data")
    console.log(action.data)
    return {
      ...state,
      isLoading: false,
      forms: {
        ...state.forms,
        saveVideoCollection: {
          ...state.forms.saveVideoCollection,
          [action.data.group]: state.forms.saveVideoCollection[
            action.data.group
          ].map((link, i) => {
            if (i === action.data.pos) {
              return {
                ...link,
                [action.data.prop]: action.data.val,
              }
            } else {
              return link
            }
          }),
        },
      },
    }
  }

  if (action.type === "TEMPLATE_TAGS_START") {
    console.log("TEMPLATE_TAGS_START")
    return {
      ...state,
      isLoadingTags: true,
    }
  }
  if (action.type === "TEMPLATE_TAGS_UPDATE") {
    console.log("TEMPLATE_TAGS_UPDATE")
    return {
      ...state,
      isLoadingTags: false,
      templatetags: action.data,
    }
  }
  if (action.type === "IMAGEUPLOAD_START") {
    console.log("IMAGEUPLOAD_START")
    return {
      ...state,
      isLoadingImageUpload: true,
    }
  }
  if (action.type === "IMAGEUPLOAD_PROGRESS") {
    console.log("IMAGEUPLOAD_PROGRESS")
    return {
      ...state,
      imageuploadprogress: {
        ...state.imageuploadprogress,
        [action.data.prop]: { progress: action.data.val, loc: action.data.loc },
      },
    }
  }
  if (action.type === "IMAGEUPLOAD_FINISH") {
    console.log("IMAGEUPLOAD_FINISH")
    return {
      ...state,
      isLoadingImageUpload: false,
      imageuploadprogress: {
        ...state.imageuploadprogress,
        [action.data.prop]: { progress: action.data.val, loc: action.data.loc },
      },
    }
  }
  if (action.type === "IMAGEUPLOAD_RESET") {
    console.log("IMAGEUPLOAD_RESET")
    return {
      ...state,
      isLoadingImageUpload: false,
      isLoadingImageDelete: false,
      imageuploadprogress: {},
      imagetags: [],
      forms: {
        ...state.forms,
        saveimage: {},
        deleteimage: [],
        deleteimagefiles: [],
      },
    }
  }

  if (action.type === "IMAGEDELETE_START") {
    console.log("IMAGEDELETE_START")
    return {
      ...state,
      isLoadingImageDelete: true,
    }
  }

  if (action.type === "IMAGEDELETE_FINISH") {
    console.log("IMAGEDELETE_FINISH")
    return {
      ...state,
      isLoadingImageDelete: "complete",
      forms: {
        ...state.forms,
        deleteimage: [],
        deleteimagefiles: [],
      },
    }
  }
  if (action.type === "IMAGEDELETE_COMPLETE") {
    console.log("IMAGEDELETE_COMPLETE")
    return {
      ...state,
      isLoadingImageDelete: false,
    }
  }

  if (action.type === "USERGROUP_CREATE_UPDATE_START") {
    console.log("USERGROUP_CREATE_UPDATE_START")

    return {
      ...state,
      isLoadingUserGroup: true,
    }
  }

  if (action.type === "USERGROUP_CREATE_UPDATE_COMPLETE") {
    console.log("USERGROUP_CREATE_UPDATE_COMPLETE")
    // console.log("action.data")
    // console.log(action.data)

    return {
      ...state,
      isLoadingUserGroup: false,
      userGroupUpdateComplete: true,
    }
  }

  if (action.type === "USERGROUP_CREATE_UPDATE_RESET") {
    console.log("USERGROUP_CREATE_UPDATE_RESET")

    return {
      ...state,
      userGroupUpdateComplete: false,
    }
  }

  if (action.type === "TEMPLATETRAY_FIELD_UPDATE") {
    console.log("TEMPLATETRAY_FIELD_UPDATE")
    // console.log("action.data")
    // console.log(action.data)

    return {
      ...state,
      templateTrayIsCollapsed: action.data,
    }
  }

  if (action.type === "IMAGELIBRARY_CONTEXT_REPLACE") {
    console.log("IMAGELIBRARY_CONTEXT_REPLACE")
    // console.log("action.data")
    // console.log(action.data)

    return {
      ...state,
      imagelibrary: action.data,
    }
  }
  if (action.type === "EFFECTS_CONTEXT_REPLACE") {
    console.log("EFFECTS_CONTEXT_REPLACE")
    // console.log("action.data")
    // console.log(action.data)

    return {
      ...state,
      effectsContext: action.data,
    }
  }
  if (action.type === "USERPROFILE_CREATE_UPDATE_START") {
    console.log("USERPROFILE_CREATE_UPDATE_START")
    // console.log("action.data")
    // console.log(action.data)

    return {
      ...state,
      isLoading: true,
    }
  }

  if (action.type === "CONV_START") {
    console.log("CONV_START")
    return {
      ...state,
      conversationActive: true,
    }
  }
  if (action.type === "CONV_END") {
    console.log("CONV_END")
    return {
      ...state,
      conversationActive: false,
    }
  }
  if (action.type === "CONV_CONTEXT") {
    console.log("CONV_CONTEXT")
    return {
      ...state,
    }
  }

  if (action.type === "SOCIAL_FIELD_UPDATE") {
    console.log("SOCIAL_FIELD_UPDATE")
    // console.log("action.data")
    // console.log(action.data)

    return {
      ...state,
      isLoading: false,
      social: {
        ...state.social,
        [action.data.prop]: action.data.val,
      },
    }
  }
  if (action.type === "SOCIAL_MODAL_USERDETAIL_CLEAR") {
    console.log("SOCIAL_MODAL_USERDETAIL_CLEAR")
    // console.log("action.data")
    // console.log(action.data)

    return {
      ...state,
      isLoading: false,
      social: {
        ...state.social,
        usertemplates: [],
        userdata: [],
      },
    }
  }

  if (action.type === "LOADING_FOLLOW_START") {
    console.log("LOADING_FOLLOW_START")
    return {
      ...state,
      isLoadingFollowCreate: true,
    }
  }
  if (action.type === "LOADING_FOLLOW_UPDATE") {
    console.log("LOADING_FOLLOW_UPDATE")
    return {
      ...state,
      isLoadingFollowCreate: false,
      userdetail: {
        ...state.userdetail,
        follow: action.data,
      },
    }
  }
  if (action.type === "SCENE_CHANGE_REPLACE") {
    console.log("SCENE_CHANGE_REPLACE")
    const currentPos = state.publisher.sceneSelected
    const currentElements = [...state.publisher.elements]
    const currentVideoElements = { ...state.publisher.videoElements }

    const revisedSceneElements = action.data.elements
      ? action.data.elements
      : currentElements
    const revisedElements = action.data.elements
      ? action.data.elements
      : action.data.publisher.loadedScenes[action.data.pos].data
    console.log("**SCENE_CHANGE_REPLACE**")
    console.log(action.data)
    console.log("**SCENE_CHANGE_REPLACE ** ELEMENTS **")
    console.log(action.data.elements)
    console.log("**SCENE_CHANGE_REPLACE ** revisedElements **")
    console.log(revisedElements)
    console.log("old video elements")
    console.log(action.data.publisher.videoElements)
    console.log("new video elements")
    console.log(action.data.publisher.loadedScenes[action.data.pos].video)
    console.log("currentElements")
    console.log(currentElements)
    console.log("state.elementsOverlayData")
    console.log(state.elementsOverlayData)
    return {
      ...state,
      publisher: {
        ...state.publisher,
        sceneSelected: action.data.pos,
        elements: revisedElements,
        videoElements:
          action.data.publisher.loadedScenes[action.data.pos].video, //keep this above the loadedScenes item below
        loadedScenes: state.publisher.loadedScenes.map((sceneItem, i) =>
          i === currentPos
            ? {
                ...sceneItem,
                // data: revisedSceneElements,
                video: currentVideoElements,
                data: revisedSceneElements,
                // data:
                //   action.data.elementOverlayData &&
                //   action.data.elementOverlayData.length > 0
                //     ? revisedSceneElements.map((element) => {
                //         return {
                //           ...element,
                //           overlayData: action.data.elementOverlayData.find(
                //             (overlayElement) => overlayElement.id === element.id
                //           )
                //             ? action.data.elementOverlayData.find(
                //                 (overlayElement) =>
                //                   overlayElement.id === element.id
                //               ).overlayData
                //             : "",
                //         }
                //       })
                //     : revisedSceneElements,
              }
            : sceneItem
        ), //transfer current content of elements and videoElements to loadedScenes
      },
      elementsOverlayData:
        action.data.elementOverlayData &&
        action.data.elementOverlayData.length > 0
          ? [
              ...state.elementsOverlayData,
              ...action.data.elementOverlayData,
              // .map((element) =>
              //   state.elementsOverlayData.findIndex(
              //     (elementArr) => elementArr.id === element.id
              //   ) !== -1
              //     ? { id: element.id, overlayData: element.overlayData }
              //     : null
              // )
              // .filter((arrItem) => arrItem !== null),
            ].filter(
              (value, index, self) =>
                index === self.findIndex((t) => t.id === value.id) //make sure the array has unique values. See: https://stackoverflow.com/a/36744732
            )
          : state.elementsOverlayData,
    }
  }
  if (action.type === "SCENE_OVERLAYDATA_UPDATE") {
    console.log("SCENE_OVERLAYDATA_UPDATE")
    console.log(action.data)
    const currentVideoElements = { ...state.publisher.videoElements }

    return {
      ...state,
      publisher: {
        ...state.publisher,
        loadedScenes: state.publisher.loadedScenes.map((sceneItem, i) =>
          i === action.data.pos
            ? {
                ...sceneItem,
                data: sceneItem.data,
                video: currentVideoElements,
                // data:
                //   action.data.elementOverlayData &&
                //   action.data.elementOverlayData.length > 0
                //     ? sceneItem.data.map((element) => {
                //         return {
                //           ...element,
                //           overlayData: action.data.elementOverlayData.find(
                //             (overlayElement) => overlayElement.id === element.id
                //           )
                //             ? action.data.elementOverlayData.find(
                //                 (overlayElement) =>
                //                   overlayElement.id === element.id
                //               ).overlayData
                //             : "",
                //         }
                //       })
                //     : sceneItem.data,
                overlayData: action.data.overlayData,
              }
            : sceneItem
        ),
      },
      elementsOverlayData:
        action.data.elementOverlayData &&
        action.data.elementOverlayData.length > 0
          ? [
              ...state.elementsOverlayData,
              ...action.data.elementOverlayData,
            ].filter(
              (value, index, self) =>
                index === self.findIndex((t) => t.id === value.id)
            ) //make sure the array has unique values. See: https://stackoverflow.com/a/36744732
          : state.elementsOverlayData,
    }
  }

  if (action.type === "SCENE_ADD") {
    console.log("SCENE_ADD")
    console.log(action.data)
    return {
      ...state,
      publisher: {
        ...state.publisher,
        loadedScenes: [
          ...state.publisher.loadedScenes,
          {
            data: [],
            scenedesc: "",
            sceneid: `scene${action.data}`,
            scenename: "",
            video: {
              audioTrackLevel: "1.0",
              audioTrackMuted: false,
              effect: [],
              origAudioLevel: "1.0",
              origAudioMuted: false,
              src: [{ loc: "static/video/placeholder.mp4" }],
              timeend: 3.0,
              timestart: 0,
            },
          },
        ],
      },
    }
  }
  if (action.type === "SCENE_DELETE") {
    console.log("SCENE_DELETE")
    console.log(action.data)
    return {
      ...state,
      publisher: {
        ...state.publisher,
        loadedScenes: state.publisher.loadedScenes.filter(
          (scene, i) => i !== action.data
        ),
      },
    }
  }
  if (action.type === "VIDEOELEMENTS_REPLACE") {
    console.log("VIDEOELEMENTS_REPLACE")
    console.log(action.data)
    if (action.data.prop === "src") {
      return {
        ...state,
        publisher: {
          ...state.publisher,
          videoElements: {
            ...state.publisher.videoElements,
            src: state.publisher.videoElements.src.map((srcItem, i) =>
              i === action.data.locPos
                ? {
                    ...srcItem,
                    loc: action.data.val,
                    timestart: 0,
                    timeend: action.data.timeEnd,
                  }
                : srcItem
            ),
          },
        },
      }
    } else if (action.data.prop === "effect") {
      // const indexer = state.publisher.videoElements.effect.findIndex(
      //   (effectItem) => effectItem.mode === action.data.locPos
      // )
      // console.log("indexer")
      // console.log(indexer)
      const addEffect = (videoObject) => {
        return videoObject.effect &&
          videoObject.effect.findIndex(
            (effectItem) => effectItem.mode === action.data.locPos
          ) !== -1
          ? videoObject.effect.map((effectItem, i) => {
              console.log("!!loadedScene - effectItem i")
              console.log(i)
              console.log("!!loadedScene - effectItem")
              console.log(effectItem)
              console.log("!!loadedScene - effectItem.mode")
              console.log(effectItem.mode)
              console.log("!!loadedScene - action.data.locPos")
              console.log(action.data.locPos)
              return effectItem.mode === action.data.locPos
                ? {
                    ...effectItem,
                    id: action.data.val,
                    val: action.data.timeEnd,
                    preview: action.data.preview,
                  }
                : effectItem
            })
          : [
              ...videoObject.effect,
              {
                //if nothing in the effect array, then add something new
                id: action.data.val,
                mode: action.data.locPos,
                val: action.data.timeEnd,
                preview: action.data.preview,
              },
            ]
      }

      return {
        ...state,
        publisher: {
          ...state.publisher,
          loadedScenes: state.publisher.loadedScenes.map((loadedScene, i) => {
            console.log(`loadedScenes i - ${i}`)

            if (i === state.publisher.sceneSelected) {
              console.log(
                `loadedScenes - state.publisher.sceneSelected - ${state.publisher.sceneSelected}`
              )
              return {
                ...loadedScene,
                video: {
                  ...loadedScene.video,
                  effect: addEffect(loadedScene.video),
                },
              }
            } else {
              return loadedScene
            }
          }),
          videoElements: {
            ...state.publisher.videoElements,
            effect: addEffect(state.publisher.videoElements),
          },
        },
      }
    } else {
      return {
        ...state,
        publisher: {
          ...state.publisher,
          videoElements: {
            ...state.publisher.videoElements,
            [action.data.prop]: action.data.val,
          },
        },
      }
    }
  }

  if (action.type === "VIDEOELEMENTS_REMOVE") {
    console.log("VIDEOELEMENTS_REMOVE")
    if (action.data.prop === "effect") {
      return {
        ...state,
        publisher: {
          ...state.publisher,
          videoElements: {
            ...state.publisher.videoElements,
            effect: state.publisher.videoElements.effect.filter(
              (effectItem) => effectItem.id !== action.data.val
            ),
          },
        },
      }
    } else {
      return {
        ...state,
        //TODO ADD ELSE STATE
      }
    }
  }

  if (action.type === "AUDIOELEMENTS_REPLACE") {
    console.log("AUDIOELEMENTS_REPLACE")
    return {
      ...state,
      publisher: {
        ...state.publisher,
        audioElements: {
          ...state.publisher.audioElements,
          [action.data.prop]: action.data.val,
        },
      },
    }
  }

  if (action.type === "VIDEO_STATUS_NEWVIDEO_UPDATE") {
    console.log("VIDEO_STATUS_NEWVIDEO_UPDATE")
    return {
      ...state,
      videoStatusNewVideoIndicator: action.data,
    }
  }

  if (action.type === "VIDEO_STATUS_DELETE_SUCCESS") {
    console.log("VIDEO_STATUS_DELETE_SUCCESS")
    return {
      ...state,
      videoStatusDeleteVideoSuccess: true,
    }
  }
  if (action.type === "VIDEO_STATUS_DELETE_RESET") {
    console.log("VIDEO_STATUS_DELETE_RESET")
    return {
      ...state,
      isLoadingVideoRenderList: false,
      videoStatusDeleteVideoSuccess: false,
      videoStatusDeleteVideoMessage: [],
    }
  }
  if (action.type === "VIDEO_STATUS_DELETE_MESSAGE") {
    console.log("VIDEO_STATUS_DELETE_MESSAGE")
    return {
      ...state,
      videoStatusDeleteVideoMessage: action.data,
    }
  }
  if (action.type === "VIDEO_STATUS_RENDERLIST_ISLOADING") {
    console.log("VIDEO_STATUS_RENDERLIST_ISLOADING")
    return {
      ...state,
      isLoadingVideoRenderList: true,
    }
  }
  if (action.type === "VIDEO_STATUS_RENDERLIST_REPLACE") {
    console.log("VIDEO_STATUS_RENDERLIST_REPLACE")
    return {
      ...state,
      isLoadingVideoRenderList: false,
      videoRenderList: action.data,
    }
  }

  if (action.type === "VIDEO_STATUS_RECHECK_INCREMENT") {
    console.log("VIDEO_STATUS_RECHECK_INCREMENT")
    return {
      ...state,
      videoStatusRecheckActive: true,
      videoStatusRecheckCounter: action.data,
    }
  }
  if (action.type === "VIDEO_STATUS_RECHECK_RESET") {
    console.log("VIDEO_STATUS_RECHECK_RESET")
    return {
      ...state,
      videoStatusRecheckActive: false,
      videoStatusRecheckCounter: 0,
    }
  }
  if (action.type === "LOADING_REPORTING") {
    console.log("LOADING_REPORTING")
    return {
      ...state,
      isLoadingReporting: true,
    }
  }
  if (action.type === "LOADING_REPORTING_COLLECTIONS") {
    console.log("LOADING_REPORTING_COLLECTIONS")
    return {
      ...state,
      reportingCollections: [],
      reportingCollectionsOffchain: [],
      isLoadingVideoCollectionsList: true,
    }
  }
  if (action.type === "LOADING_REPORTING_COLLECTIONS_KEYWORD") {
    console.log("LOADING_REPORTING_COLLECTIONS_KEYWORD")
    return {
      ...state,
      isLoadingVideoCollectionsKeyword: true,
    }
  }
  if (action.type === "LOADING_REPORTING_COLLECTIONS_KEYWORD_RESET") {
    console.log("LOADING_REPORTING_COLLECTIONS_KEYWORD_RESET")
    return {
      ...state,
      isLoadingVideoCollectionsKeyword: false,
    }
  }
  if (action.type === "LOADING_REPORTING_VIDEOS_REPLACE") {
    console.log("LOADING_REPORTING_REPLACE")
    return {
      ...state,
      isLoadingReporting: false,
      reportingVideos: action.data,
    }
  }
  if (action.type === "LOADING_REPORTING_VIDEORESUMES_REPLACE") {
    console.log("LOADING_REPORTING_VIDEORESUMES_REPLACE")
    return {
      ...state,
      isLoadingReporting: false,
      reportingVideoResumes: action.data,
    }
  }
  if (action.type === "LOADING_REPORTING_COLLECTIONS_REPLACE") {
    console.log("LOADING_REPORTING_COLLECTIONS_REPLACE")
    return {
      ...state,
      isLoadingVideoCollectionsList: false,
      reportingCollections:
        action.data && action.data.length > 0
          ? action.data.map((item) => ({
              ...item,
              manualContractAddr: "",
              manualTokenId: "",
              manualTokenIdMin: "",
              manualTokenIdMax: "",
              manualNetwork: "mainnet",
            }))
          : [],
    }
  }
  if (action.type === "LOADING_REPORTING_COLLECTIONS_OFFCHAIN_REPLACE") {
    console.log("LOADING_REPORTING_COLLECTIONS_OFFCHAIN_REPLACE")
    return {
      ...state,
      isLoadingVideoCollectionsList: false,
      reportingCollectionsOffchain:
        action.data && action.data.length > 0 ? action.data : [],
    }
  }
  if (action.type === "LOADING_REPORTING_COLLECTIONS_ITEMGROUP_REPLACE") {
    console.log("LOADING_REPORTING_COLLECTIONS_OFFCHAIN_REPLACE")
    return {
      ...state,
      isLoading: false,
      reportingCollectionsItemGroup:
        action.data && action.data.length > 0 ? action.data : [],
    }
  }
  if (action.type === "LOADING_REPORTING_REWARDELIGIBLE_REPLACE") {
    console.log("LOADING_REPORTING_REWARDELIGIBLE_REPLACE")
    return {
      ...state,
      isLoadingRewardEligible: false,
      reportingRewardEligible: action.data,
    }
  }
  if (action.type === "LOADING_REPORTING_REWARDREDEEM_REPLACE") {
    console.log("LOADING_REPORTING_REWARDREDEEM_REPLACE")
    return {
      ...state,
      isLoadingRewardRedeem: false,
      reportingRewardRedeem: action.data,
    }
  }
  if (action.type === "LOADING_REPORTING_REWARDHISTORY_REPLACE") {
    console.log("LOADING_REPORTING_REWARDHISTORY_REPLACE")
    return {
      ...state,
      isLoadingRewardHistory: false,
      reportingRewardHistory: action.data,
    }
  }
  if (action.type === "LOADING_USERNFTS") {
    console.log("LOADING_USERNFTS")
    return {
      ...state,
      isLoadingUserNfts: true,
    }
  }
  if (action.type === "LOADING_NFTPROJECT") {
    console.log("LOADING_NFTPROJECT")
    return {
      ...state,
      isLoadingNftProject: true,
    }
  }
  if (action.type === "LOADING_REWARDREDEEM") {
    console.log("LOADING_REWARDREDEEM")
    return {
      ...state,
      isLoadingRewardRedeem: true,
    }
  }
  if (action.type === "LOADING_REWARDELIGIBLE") {
    console.log("LOADING_REWARDELIGIBLE")
    return {
      ...state,
      isLoadingRewardEligible: true,
    }
  }
  if (action.type === "LOADING_REWARDHISTORY") {
    console.log("LOADING_REWARDHISTORY")
    return {
      ...state,
      isLoadingRewardHistory: true,
    }
  }
  if (action.type === "VIDEO_EFFECTS_REPLACE") {
    console.log("VIDEO_EFFECTS_REPLACE")
    return {
      ...state,
      isLoading: false,
      videoEffects: action.data,
    }
  }
  if (action.type === "AUDIO_TRACKS_REPLACE") {
    console.log("AUDIO_TRACKS_REPLACE")
    return {
      ...state,
      isLoading: false,
      audioTracks: action.data,
    }
  }
  if (action.type === "VIDEO_RENDER_CURRENT_STATUS_REPLACE") {
    console.log("VIDEO_RENDER_CURRENT_STATUS_REPLACE")
    console.log(action.data)
    return {
      ...state,
      videoRenderCurrentStatus: action.data,
    }
  }
  if (action.type === "LOGIN_CURRENT_STATUS_REPLACE") {
    console.log("LOGIN_CURRENT_STATUS_REPLACE")
    console.log(action.data)
    return {
      ...state,
      loginCurrentStatus: action.data,
    }
  }
  if (action.type === "LOGIN_CURRENT_SUSPENDED_REPLACE") {
    console.log("LOGIN_CURRENT_SUSPENDED_REPLACE")
    console.log(action.data)
    return {
      ...state,
      loginSuspended: action.data,
    }
  }

  if (action.type === "GETTING_STARTED_POSITION_REPLACE") {
    console.log("GETTING_STARTED_POSITION_REPLACE")
    console.log(action.data)
    return {
      ...state,
      gettingStartedPos: action.data,
    }
  }
  if (action.type === "GETTING_STARTED_MODULE_REPLACE") {
    console.log("GETTING_STARTED_MODULE_REPLACE")
    console.log(action.data)
    return {
      ...state,
      gettingStartedModule: action.data,
    }
  }

  if (action.type === "VIDEO_TEMPLATE_PREVIEW_REPLACE") {
    console.log("** VIDEO_TEMPLATE_PREVIEW_REPLACE")
    console.log(action.data)
    return {
      ...state,
      templateVideoPreview: {
        id: action.data.id,
        imageid: action.data.imageid,
        title: action.data.title,
      },
    }
  }
  if (action.type === "USER_STORAGE_REPLACE") {
    console.log("USER_STORAGE_REPLACE")
    console.log(action.data)
    return {
      ...state,
      userstorage: action.data,
    }
  }
  if (action.type === "SCENE_CHANGE_ATTR") {
    console.log("SCENE_CHANGE_ATTR")
    console.log(action.data)
    return {
      ...state,
      publisher: {
        ...state.publisher,
        loadedScenes: state.publisher.loadedScenes.map((scene, i) =>
          i === action.data.pos
            ? { ...scene, [action.data.prop]: action.data.attr }
            : scene
        ),
      },
    }
  }
  if (action.type === "MINTING_ITEM_REPLACE") {
    console.log("MINTING_ITEM_REPLACE")
    console.log(action.data)
    return {
      ...state,
      mintingItem: action.data,
    }
  }
  if (action.type === "MINTING_ITEM_UPDATE") {
    console.log("MINTING_ITEM_UPDATE")
    console.log(action.data)
    return {
      ...state,
      mintingItem: {
        ...state.mintingItem,
        [action.data.prop]: action.data.val,
      },
    }
  }
  if (action.type === "USER_NFTS_REPLACE") {
    console.log("USER_NFTS_REPLACE")
    console.log(action.data)
    return {
      ...state,
      userNfts: action.data,
      isLoadingUserNfts: false,
    }
  }
  if (action.type === "UPDATE_WALLET_ADDRESS") {
    console.log("UPDATE_WALLET_ADDRESS")
    return {
      ...state,
      walletAddress: action.data,
    }
  }
  if (action.type === "UPDATE_WALLET_CHAIN") {
    console.log("UPDATE_WALLET_CHAIN")
    return {
      ...state,
      walletChainId: action.data,
    }
  }
  if (action.type === "UPDATE_WALLET_PROVIDER") {
    console.log("UPDATE_WALLET_PROVIDER")
    return {
      ...state,
      walletProvider: action.data,
    }
  }
  if (action.type === "UPDATE_WALLET_STATUS") {
    console.log("UPDATE_WALLET_STATUS")
    return {
      ...state,
      walletStatus: action.data,
    }
  }
  if (action.type === "UPDATE_WALLET_WEB3") {
    console.log("UPDATE_WALLET_WEB3")
    return {
      ...state,
      walletWeb3: action.data,
    }
  }
  if (action.type === "UPDATE_WALLET_SIGNER") {
    console.log("UPDATE_WALLET_SIGNER")
    return {
      ...state,
      walletSigner: action.data,
    }
  }
  if (action.type === "UPDATE_ALCHEMY_NFT_LIST") {
    console.log("UPDATE_ALCHEMY_NFT_LIST")
    return {
      ...state,
      userNftsAlchemy: action.data,
      isLoadingUserNfts: false,
    }
  }
  if (action.type === "UPDATE_NFT_TRANSACTION_STATUS") {
    console.log("UPDATE_NFT_TRANSACTION_STATUS")
    console.log("action.data")
    console.log(action.data)

    let returnObj = {
      ...state,
      nftTransactionStatus: action.data,
    }

    if (action.data && action.data.success === false) {
      returnObj.isLoadingCollectionCreate = false
      returnObj.isLoadingVideoRenderList = false
    }

    return returnObj
  }
  if (action.type === "RESET_NFT_TRANSACTION_STATUS") {
    console.log("RESET_NFT_TRANSACTION_STATUS")

    return {
      ...state,
      nftTransactionStatus: initialNftTransactionStatus,
      isLoadingCollectionCreate: false,
    }
  }

  if (action.type === "NFT_MULTIMINT_ITEM_UPDATE_PROP") {
    console.log("NFT_MULTIMINT_ITEM_UPDATE_PROP")
    console.log(action.data)

    return {
      ...state,
      mintingMultiChanged: true,
      mintingItemMulti: state.mintingItemMulti.map((item, i) =>
        action.data.pos === i
          ? { ...item, [action.data.prop]: action.data.val }
          : item
      ),
    }
  }
  if (action.type === "NFT_MULTIMINT_ITEM_REPLACE") {
    console.log("NFT_MULTIMINT_ITEM_REPLACE")
    console.log(action.data)

    return {
      ...state,
      mintingMultiChanged: false,
      mintingItemMulti: action.data,
    }
  }
  if (action.type === "NFT_MULTIMINT_ITEM_ADD") {
    console.log("NFT_MULTIMINT_ITEM_ADD")
    console.log(action.data)

    return {
      ...state,
      mintingMultiChanged: true,
      mintingItemMulti: [...state.mintingItemMulti, action.data],
    }
  }
  if (action.type === "NFT_MULTIMINT_ITEM_DELETE") {
    console.log("NFT_MULTIMINT_ITEM_DELETE")
    console.log(action.data)

    return {
      ...state,
      mintingMultiChanged: true,
      mintingItemMulti: state.mintingItemMulti.filter(
        (item, i) => i !== action.data.pos
      ),
    }
  }

  if (action.type === "NFT_MULTIMINT_METADATA_UPDATE_PROP") {
    console.log("NFT_MULTIMINT_METADATA_UPDATE_PROP")
    console.log(action.data)

    return {
      ...state,
      mintingMultiChanged: true,
      mintingItemMulti: state.mintingItemMulti.map((item, i) =>
        action.data.pos === i
          ? {
              ...item,
              metadata: item.metadata.map((metadataItem, j) =>
                action.data.itempos === j
                  ? { ...metadataItem, [action.data.prop]: action.data.val }
                  : metadataItem
              ),
            }
          : item
      ),
    }
  }
  if (action.type === "NFT_MULTIMINT_METADATA_ADD") {
    console.log("NFT_MULTIMINT_METADATA_ADD")
    console.log(action.data)
    return {
      ...state,
      mintingMultiChanged: true,
      mintingItemMulti: state.mintingItemMulti.map((item, i) =>
        action.data.pos === i
          ? {
              ...item,
              metadata: [...item.metadata, { title: "", value: "" }],
            }
          : item
      ),
    }
  }
  if (action.type === "NFT_MULTIMINT_METADATA_DELETE") {
    console.log("NFT_MULTIMINT_METADATA_DELETE")
    console.log(action.data)

    return {
      ...state,
      mintingMultiChanged: true,
      mintingItemMulti: state.mintingItemMulti.map((item, i) =>
        action.data.pos === i
          ? {
              ...item,
              metadata: item.metadata.filter(
                (metadataItem, j) => action.data.itempos !== j
              ),
            }
          : item
      ),
    }
  }

  if (action.type === "UPDATE_NFT_PROJECTS") {
    console.log("UPDATE_NFT_PROJECTS")
    return {
      ...state,
      nftProjects: action.data,
      isLoadingNftProject: false,
    }
  }
  if (action.type === "UPDATE_NFT_PROJECT_SELECTED") {
    console.log("UPDATE_NFT_PROJECT_SELECTED")
    return {
      ...state,
      nftProjectSelected: action.data,
    }
  }
  if (action.type === "UPDATE_NFT_PROJECT_SELECTED_FIELD") {
    console.log("UPDATE_NFT_PROJECT_SELECTED_FIELD")
    console.log("UPDATE_NFT_PROJECT_SELECTED_FIELD - action.data.prop")
    console.log(action.data.prop)
    console.log("UPDATE_NFT_PROJECT_SELECTED_FIELD - action.data.val")
    console.log(action.data.val)
    return {
      ...state,
      nftProjectSelected: {
        ...state.nftProjectSelected,
        [action.data.prop]: action.data.val,
      },
    }
  }
  if (action.type === "UPDATE_NFT_PROJECT_ASSETS_WORKING") {
    console.log("UPDATE_NFT_PROJECT_ASSETS_WORKING")
    return {
      ...state,
      nftAssetsWorking: action.data,
      isLoading: false,
    }
  }
  if (action.type === "UPDATE_NFT_PROJECT_METADATA_WORKING") {
    console.log("UPDATE_NFT_PROJECT_METADATA_WORKING")
    return {
      ...state,
      nftMetadataWorking: action.data,
      isLoading: false,
    }
  }
  if (action.type === "UPDATE_NFT_MANUAL_METADATA") {
    console.log("UPDATE_NFT_MANUAL_METADATA")
    return {
      ...state,
      manualTokenMetadata: action.data,
      isLoading: false,
    }
  }
  if (action.type === "UPDATE_PERMISSION_MEMBERS") {
    console.log("UPDATE_PERMISSION_MEMBERS")
    return {
      ...state,
      permissionMembers: action.data,
      isLoading: false,
    }
  }
  if (action.type === "RESET_PERMISSION_MEMBERS") {
    console.log("RESET_PERMISSION_MEMBERS")
    return {
      ...state,
      permissionMembers: [],
      isLoading: false,
    }
  }
  if (action.type === "UPDATE_PLANNER_TEMPLATES") {
    console.log("UPDATE_PLANNER_TEMPLATES")
    return {
      ...state,
      plannerTemplates: action.data,
      isLoading: false,
    }
  }
  if (action.type === "UPDATE_PLATFORM_SETTINGS") {
    console.log("UPDATE_PLATFORM_SETTINGS")
    return {
      ...state,
      platformSettings: action.data,
      isLoading: false,
    }
  }
  if (action.type === "UPDATE_PLATFORM_SETTINGS_SAVE_SUCCESS") {
    console.log("UPDATE_PLATFORM_SETTINGS_SAVE_SUCCESS")
    return {
      ...state,
      isLoading: false,
      platformSettingsSaveSuccess: true,
    }
  }
  if (action.type === "UPDATE_PLATFORM_SETTINGS_RESET") {
    console.log("UPDATE_PLATFORM_SETTINGS_RESET")
    return {
      ...state,
      isLoading: false,
      platformSettingsSaveSuccess: false,
    }
  }
  if (action.type === "UPDATE_PLATFORM_SETTINGS_ITEM") {
    console.log("UPDATE_PLATFORM_SETTINGS_ITEM")
    console.log(action.data)
    if (action.data.mode === "add") {
      return {
        ...state,
        platformSettings: {
          ...state.platformSettings,
          [action.data.group]: [
            ...state.platformSettings[action.data.group],
            {
              id: `custom${action.data.id}`,
              cat: `${action.data.cat}`,
              label: ``,
              publish: 1,
              subcat: [],
            },
          ],
        },
        isLoading: false,
      }
    } else if (action.data.mode === "addsub") {
      return {
        ...state,
        platformSettings: {
          ...state.platformSettings,
          [action.data.group]: state.platformSettings[action.data.group].map(
            (item) => {
              if (item.id === action.data.id) {
                return {
                  ...item,
                  subcat: [
                    ...(item?.subcat ? item.subcat : []),
                    {
                      id: `${action.data.cat}-${action.data.subcat}`,
                      cat: action.data.cat,
                      label: "",
                      content: "",
                    },
                  ],
                }
              } else {
                return item
              }
            }
          ),
        },
        isLoading: false,
      }
    } else if (action.data.mode === "delete") {
      return {
        ...state,
        platformSettings: {
          ...state.platformSettings,
          [action.data.group]: state.platformSettings[action.data.group].filter(
            (item, i) => item.id !== action.data.id
          ),
        },
      }
    } else if (action.data.mode === "deletesub") {
      return {
        ...state,
        platformSettings: {
          ...state.platformSettings,
          [action.data.group]: state.platformSettings[action.data.group].map(
            (item) => {
              if (item.id === action.data.id) {
                return {
                  ...item,
                  subcat: item?.subcat
                    ? item.subcat.filter(
                        (subitem) => subitem.id !== action.data.subcat
                      )
                    : [],
                }
              } else {
                return item
              }
            }
          ),
        },
      }
    } else if (action.data.mode === "updatecontent") {
      return {
        ...state,
        platformSettings: {
          ...state.platformSettings,
          [action.data.group]: [
            ...state.platformSettings[action.data.group].map((item) => {
              if (item.cat === action.data.cat) {
                return {
                  ...item,
                  [action.data.prop]: action.data.content,
                }
              } else {
                return item
              }
            }),
          ],
        },
      }
    } else if (action.data.mode === "updatecontentsub") {
      return {
        ...state,
        platformSettings: {
          ...state.platformSettings,
          [action.data.group]: state.platformSettings[action.data.group].map(
            (item) => {
              if (item.id === action.data.id) {
                return {
                  ...item,
                  subcat: item?.subcat?.map((subitem) => {
                    if (subitem.id === action.data.subcat) {
                      return {
                        ...subitem,
                        content: action.data.content,
                        label: action.data.content,
                      }
                    } else {
                      return subitem
                    }
                  }),
                }
              } else {
                return item
              }
            }
          ),
        },
        isLoading: false,
      }
    }
  }

  if (action.type === "TOKEN_CONFIG_REPLACE") {
    console.log("TOKEN_CONFIG_REPLACE")
    console.log(action.data)
    let returnObj = {}
    if (action.data && action.data.tokenConfigData) {
      console.log("cond-1")
      returnObj = {
        id: action.data.tokenConfigData.id,
        tokenConfigData: action.data.tokenConfigData,
        tokenConfigSortOrder: action.data.tokenConfigSortOrder,
        tokenConfigLinkSlug: action.data.tokenConfigLinkSlug,
        tokenConfigDisplayCategory: action.data.tokenConfigDisplayCategory,
        tokenConfigDisplayCategorySub:
          action.data.tokenConfigDisplayCategorySub,
      }
    } else {
      console.log("cond-2")

      returnObj = {
        tokenConfigData: state.forms.saveVideoCollection.tokenConfigData,
      }
    }
    return {
      ...state,
      isLoadingTokenConfig: false,
      forms: {
        ...state.forms,
        saveVideoCollection: {
          ...initialSaveVideoCollectionState,
          ...returnObj,
        },
      },
    }
  }

  if (action.type === "AIFILES_MANAGE") {
    console.log("AIFILES_MANAGE")
    console.log("action.data")
    console.log(action.data)
    console.log("state.forms.saveVideoCollection")
    console.log(state.forms.saveVideoCollection)

    if (action.data.mode === "add") {
      return {
        ...state,
        forms: {
          ...state.forms,
          saveVideoCollection: {
            ...state.forms.saveVideoCollection,
            [action.data.group]: [
              ...state.forms.saveVideoCollection[action.data.group],
              {
                id: action.data.id,
                filename: action.data.filename,
                filetype: action.data.filetype,
              },
            ],
          },
        },
      }
    } else if (action.data.mode === "delete") {
      return {
        ...state,
        forms: {
          ...state.forms,
          saveVideoCollection: {
            ...state.forms.saveVideoCollection,
            [action.data.group]: state.forms.saveVideoCollection[
              action.data.group
            ].filter((item, i) => item.id !== action.data.id),
          },
        },
      }
    }
  }

  if (action.type === "LOADING_PLANNER_GENERATE") {
    console.log("LOADING_PLANNER_GENERATE")
    return {
      ...state,
      isLoadingPlannerGenerate: action.data,
    }
  }
  if (action.type === "LOADING_PLANNER_COMPLETE") {
    console.log("LOADING_PLANNER_COMPLETE")
    return {
      ...state,
      isLoadingPlannerGenerate: 0,
    }
  }
  if (action.type === "EVENT_ACCESS_ROLES_REPLACE") {
    console.log("EVENT_ACCESS_ROLES_REPLACE")
    return {
      ...state,
      eventAccessRoles: action.data,
      isLoading: false,
    }
  }
  if (action.type === "EVENT_ACCESS_ROLES_CREATED") {
    console.log("EVENT_ACCESS_ROLES_CREATED")
    return {
      ...state,
      eventAccessRoleCreateComplete: action.data,
      eventAccessRolesError: null,
    }
  }
  if (action.type === "EVENT_ACCESS_ROLES_ERROR") {
    console.log("EVENT_ACCESS_ROLES_ERROR")
    return {
      ...state,
      eventAccessRolesError: action.data,
      isLoading: false,
    }
  }

  return state
}

const initialTemplateCreateError = {
  screenername: false,
  screenerkeyword: false,
  screenerpushcampaignlength: false,
  screenerpushcampaignitems: [],
  screenerpushcampaignitemserror: false,
  prompttext: false,
  screenerlogo: false,
  imagewidth: false,
  imagesize: false,
  imageextension: false,
  uploadtext: false,
  uploadsubtext: false,
  surveylength: false,
  surveyitems: [],
  surveyerror: false,
}

const initialTemplateCreate = {
  inputmode: "create",
  survey: [],
  surveycounter: 1,
  criteria: [],
  criteriacounter: 1,
  landing: {
    landingdata: [{ lang: "en", uploadtext: "", uploadsubtext: "" }],
  },
  prompts: [{ lang: "en", data: [{ text: "" }] }],
  screener: {
    mode: "response",
    keyword: "",
    keywordorig: "",
    pushcampaign: [],
    pushcampaignorig: [],
  },
  error: initialTemplateCreateError,
}

const initialSendGroupCreateError = {
  sendgroupname: false,
  sendgroupuserslength: false,
  sendgroupusersitems: [],
  sendgroupuserserror: false,
}

const initialSendGroupCreate = {
  users: [],
  deliver: {
    calchour: 9,
    meridian: "am",
    rawhour: 9,
    timezone: "US/Mountain",
    utchrs: 16,
    utcmins: 960,
  },
  sendgroup: { name: "" },
  error: initialSendGroupCreateError,
}

const ts = Math.round(new Date().getTime() / 1000)

// const tempGlobalLayers = [
//   {
//     pos: 1,
//     id: "layer1",
//     mode: "video",
//     name: "Base video",
//   },
//   {
//     pos: 2,
//     id: "layer2",
//     mode: "image",
//     name: "Overlay",
//   },
// ]

const tempElements = [
  // {
  //   shape: "rect",
  //   x: 0,
  //   y: 0,
  //   width: 500,
  //   height: 500,
  //   fill: "white",
  //   id: "rect1",
  // },
]
const tempVideoElements = []
const sceneSample = [
  { id: "", name: "", desc: "", elements: "", video: [{ pos: 0, src: "" }] },
]

const initialNftTransactionStatus = {
  timer: "",
  status: "",
  success: "",
}

const initialLoadedScene = {
  data: [],
  scenedesc: "",
  sceneid: "",
  scenename: "",
  video: {
    audioTrackLevel: "1.0",
    audioTrackMuted: false,
    effect: [],
    origAudioLevel: "1.0",
    origAudioMuted: false,
    src: [{ loc: "static/video/placeholder.mp4" }],
    timeend: 3.0,
    timestart: 0,
  },
}
const initialPublisherCreate = {
  mode: "image",
  imagemode: "resize",
  templates: [],
  usertemplates: [],
  loadedScenes: [],
  scenes: [],
  sceneSelected: 0,
  templateSelected: "",
  templateLocalId: "",
  elements: tempElements,
  videoElements: tempVideoElements,
  audioElements: {
    audioTrackId: "",
    audioTrackTitle: "",
    audioTrackSrc: "",
    audioPreviewId: "",
    audioPreviewSrc: "static/audio/silence-250ms.mp3",
  },
  sceneLayerSelected: {},
  elementSelected: {},
  stage: {
    id: "square",
    w: 500,
    h: 500,
    pixelRatio: 1,
  },
  imageCropAreaShowTools: false,
  imageCropArea: {
    height: 640,
    width: 360,
    // x: 0,
    // y: 0,
  },

  webp: true,
  // error: initialTemplateCreateError,
}

const initialFormsNotifyState = { media: [] }
const initialSaveVideoCollectionState = {
  publish: 1,
  carousel: 1,
  link: [],
  cta: [],
  aiFiles: [],
  dataFields: [],
  videos: [],
  nftAuth: [],
  nftAuthOffchain: [],
  landing: {},
  image: "",
  intro: { active: 0 },
  sms: { active: 0 },
  metadata: 1,
  keywordAvailable: "initial",
  keyword: "",
  desc: "",
  redeemlimit: 1,
  redeemmode: "token",
  redeemtype: "qrcode",
  postText: "",
  rewardProvider: "",
  expirationdate: "",
  expirationmode: "none",
  mdxEditorPos: null,
  eventSendId: null,
  eventEditorId: "",
  eventSendMessage: [],
  eventSendIcon: "Article",
  eventSendTemplate: "custom",
  eventSendCategory: "",
  eventSendDateAttestation: "",
  // eventOffchain: false,
  // mintOffchain: false,
  instructionsTitle: "",
  instructionsContent: "",
  rewardValue: "1",
  rewardCurrency: "USD",
  manualContractAddr: "",
  manualTokenId: "",
  manualTokenIdMin: "",
  manualTokenIdMax: "",
  manualNetwork: "mainnet",
  plannerCategorySelected: "rawmaterials",
  plannerAttributesSelected: [],
  // plannerAttributesSelected: [{ id: "", cat: "", content: "" }],
  plannerProductMedia: {},
  plannerEditorId: "",
  displayTitle: true,
  tokenConfigData: {
    id: "",
    network: "",
    contract: "",
    tokenid: "",
    title: "",
    media: "",
  },
  tokenConfigSortOrder: "none",
  tokenConfigDisplayCategory: 0,
  tokenConfigDisplayCategorySub: 1,
  tokenCollectionImageTile: "cover",
  tokenCollectionPosterPos: null,
  imageMarqueeChanged: false,
  imageMarqueeSrc: "",
  currentMdxEditor: "",
  errorGenerate: {},
  generativeCredits: {},
  generativeLastRefineId: "",
  generativeLastRawResponse: "",
  genAiOptionWordCount: 75,
  genAiOptionTemp: 1,
  genAiFreqPenalty: 0,
  supportComment: [],
  supportCommentNew: "",
}
const initialRewardRedeem = {
  walletAddress: "",
  rewardId: "",
  paramsChanged: false,
}

const initialState = {
  loginMessage: "",
  isLoading: false,
  isLoadingMediaPreview: false,
  isLoadingNotifyCreate: false,
  isLoadingTemplateCreate: false, //used
  isLoadingTemplateFetch: false, //used
  isLoadingTemplateDeleteCompleted: false, //used
  isLoadingCollectionCreate: false, //used
  isLoadingImageUpload: false, //used
  isLoadingImageDelete: false, //used
  isLoadingVideoCreatedGrouped: false,
  isLoadingVideoUsers: false,
  isLoadingVideoMini: false,
  isLoadingCanvasImage: false,
  isLoadingUserGroup: false,
  isLoadingTags: false,
  isLoadingFollowCreate: false,
  isProfileUpdateComplete: false,
  isLoadingReporting: false,
  isLoadingStockCrop: false,
  isLoadingUserNfts: false,
  isLoadingNftProject: false,
  isLoadingPlannerGenerate: 0,

  publisher: initialPublisherCreate,
  social: { keywordtemplates: [], usertemplates: [], userdata: {} },
  notify: [],
  userdetail: {
    follow: [],
  },
  userdetailemail: {
    email: "",
    mobile: "",
    verifycode: "",
    displayname: "",
  },
  userlogin: {},
  usersub: "",
  userstorage: { media: 0, video: 0, total: 0 },
  company: {
    // users: [] // keep this commented or will mess up componentDidMount check in consoleHome
  },
  forms: {
    notify: initialFormsNotifyState,
    savetemplate: {},
    edittemplate: {},
    saveVideoCollection: initialSaveVideoCollectionState,
    rewardRedeem: initialRewardRedeem,
    tag: {},
    workingtags: [],
    saveimage: {}, //used on imageLibraryFormUpload
    deleteimage: [], //used on imagePreviewTray
    deleteimagefiles: [], //used on imagePreviewTray
    previewImageLoaded: false,
    social: { searchkeyword: "" },
    usersadd: [],
    usersdelete: [],
    usersmodify: [],
    userdetail: {},
    userdetailemail: {
      email: "",
      mobile: "",
      verifycode: "",
      displayname: "",
      error: "",
    },
    plannerTemplateSaveData: {},
    qrcode: {
      colordots: "#000000", //black
      colorbg: "#00000000", //transparent
    },
  },
  templatetags: [],
  imagetags: [],
  share: {},
  imageuploadprogress: 0,
  imageexport: {},
  imagelibrary: "brand",
  effectsContext: "video",
  activeTool: "canvas",
  activeSection: "video",
  sendgroupcreate: initialSendGroupCreate,
  emailVerifyMode: false,
  userGroupUpdateComplete: false,
  templateTrayIsCollapsed: false,
  conversationActive: false,
  videoStatusRecheckCounter: 0,
  videoStatusRecheckActive: false,
  videoRenderList: [],
  videoRenderCurrentStatus: { stage: "", pos: 0 },
  isLoadingTokenConfig: false,
  isLoadingVideoRenderList: false,
  isLoadingVideoCollectionsList: false,
  isLoadingVideoCollectionsKeyword: false,
  isLoadingRewardEligible: false,
  isLoadingRewardRedeem: false,
  isLoadingRewardHistory: false,
  videoStatusNewVideoIndicator: false,
  videoStatusDeleteVideoSuccess: false,
  videoStatusDeleteVideoMessage: [],
  reportingVideos: [],
  reportingVideoResumes: [],
  reportingCollections: [],
  reportingCollectionsOffchain: [],
  reportingCollectionsItemGroup: [],
  reportingRewardEligible: {},
  reportingRewardRedeem: {},
  reportingRewardHistory: [],
  videoEffects: [],
  audioTracks: [],
  loginCurrentStatus: "",
  loginSuspended: [],
  gettingStartedPos: 0,
  gettingStartedModule: "",
  templateVideoPreview: { id: null, imageid: null, title: null },
  userSubscription: {},
  elementsOverlayData: [],
  mintingItem: {},
  mintingItemMulti: [],
  userNfts: [],
  userNftsAlchemy: [],
  collectionPosts: [],
  collectionMessaging: [],
  walletAddress: "",
  walletChainId: "",
  walletProvider: "",
  walletStatus: false,
  walletWeb3: null,
  walletSigner: null,
  nftTransactionStatus: initialNftTransactionStatus,
  nftProjects: [],
  nftProjectSelected: {},
  nftAssetsWorking: [],
  nftMetadataWorking: [],
  mintingMultiChanged: false,
  manualTokenMetadata: {},
  permissionMembers: [],
  plannerTemplates: [],
  platformSettings: { catOverride: [], catCustom: [] },
  platformSettingsSaveSuccess: false,
  offchainOptions: {
    mintOffchain: false,
    eventOffchain: false,
    plannerOffchain: false,
  },
  eventAccessRoles: [],
  eventAccessRoleCreateComplete: false,
  eventAccessRolesError: null,
}

const createStore = () =>
  reduxCreateStore(reducer, initialState, applyMiddleware(thunk))

export default createStore
